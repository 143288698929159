import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import posthog from 'posthog-js';
import Navbar from './components/Navbar';
import BYOD from './components/BYOD';
import Landing_Bryce from './components/Landing_Bryce.tsx';
import ProgressBar from './components/ProgressBar';
import About from './components/About';
import ContactForm from './components/ContactForm';
import SuccessPage from './components/SuccessPage.tsx';
import CancelPage from './components/CancelPage.tsx';
import './App.css';

const categories = [
  { id: 'Frames', display: 'Frame' },
  { id: 'Motors', display: 'Motors' },
  { id: 'Propellers', display: 'Propellers' },
  { id: 'FlightControllers', display: 'Flight Controller' },
  { id: 'ESCs', display: 'ESCs' },
  { id: 'Batteries', display: 'Battery' },
  { id: 'Receivers', display: 'Receiver' },
  { id: 'VideoTransmitters', display: 'VTX' },
  { id: 'FPVCameras', display: 'Camera' }
];

function AppContent() {
  const [currentStep, setCurrentStep] = useState(1);
  const [activeCategory, setActiveCategory] = useState(categories[0].id);
  const [totalPrice, setTotalPrice] = useState(0);
  const location = useLocation();

  const resetToChooseDrone = () => {
    setCurrentStep(1);
    setActiveCategory(categories[0].id);
    setTotalPrice(0);
    posthog.capture('Reset to Choose Drone');
  };

  // Check if the current path is not the landing page
  const showNavbar = location.pathname !== '/';

  return (
    <>
      {showNavbar && <Navbar onResetToChooseDrone={resetToChooseDrone} />}
      <div className="main-content">
        <Routes>
          <Route path="/" element={<Landing_Bryce />} />
          <Route path="/about" element={<Landing_Bryce />} />
          <Route path="/contact" element={<Landing_Bryce />} />
          <Route path="/success" element={<SuccessPage />} />
          <Route path="/cancel" element={<CancelPage />} />
        </Routes>
      </div>
      {location.pathname === '/build' && (
        <div className="progress-bar-wrapper">
          <ProgressBar 
            currentStep={currentStep}
            totalSteps={3}
            stepName={currentStep === 1 ? "Choose Drone Type" : currentStep === 2 ? "Select Components" : "Review"}
            isSelectionMade={true}
            onNextClick={() => {
              setCurrentStep(prev => Math.min(prev + 1, 3));
              posthog.capture('Next Step', { step: currentStep + 1 });
            }}
            onPreviousClick={() => {
              setCurrentStep(prev => Math.max(prev - 1, 1));
              posthog.capture('Previous Step', { step: currentStep - 1 });
            }}
            totalPrice={totalPrice}
            categories={categories}
            activeCategory={activeCategory}
            onCategoryChange={(category) => {
              setActiveCategory(category);
              posthog.capture('Category Changed', { category });
            }}
          />
        </div>
      )}
    </>
  );
}

function App() {
  useEffect(() => {
    // Initialize PostHog
    const posthogApiKey = process.env.REACT_APP_POSTHOG_API_KEY;
    const posthogHost = process.env.REACT_APP_POSTHOG_HOST;

    if (posthogApiKey && posthogHost) {
      posthog.init(posthogApiKey, {
        api_host: posthogHost
      });
    }
  }, []);

  return (
    <Router>
      <div className="App">
        <AppContent />
      </div>
    </Router>
  );
}

export default App;